var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", [
      _c(
        "a",
        {
          staticClass: "brand-common-btn",
          style: _vm.buttonStyle,
          attrs: { "data-cy": "download-button" },
          on: { click: _vm.getDownloadUrl }
        },
        [
          _c("span", { style: _vm.buttonBeforeStyle }),
          _vm._v(" 下载 "),
          _c("span", { style: _vm.buttonAfterStyle })
        ]
      ),
      _c("div", { staticClass: "tip_text" }, [
        _c(
          "a",
          {
            staticClass: "solution",
            attrs: {
              "data-cy": "solution-link",
              href: "/solution.html",
              target: "_blank"
            },
            on: { click: _vm.clickHelpDoc }
          },
          [_vm._v(" 成人APP会被误报病毒 忽略即可(查看帮助) ")]
        )
      ])
    ]),
    _c("a", {
      ref: "android_download_link",
      staticStyle: { display: "none" },
      attrs: { href: _vm.android_download_url }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }