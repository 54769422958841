var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "wrapper__buttons" },
        _vm._l(_vm.activeButtons, function(btn, index) {
          return _c(
            "a",
            {
              key: btn.type,
              staticClass: "brand-common-btn",
              style: _vm.buttonStyle,
              attrs: {
                "data-cy": "download-button-" + btn.type,
                "data-clipboard-text": _vm.clipboardText,
                "data-clipboard": btn.type,
                target: "_blank",
                rel: "noreferrer"
              },
              on: {
                click: function($event) {
                  return _vm.onBtnClick(btn)
                }
              }
            },
            [
              index === 0
                ? _c("DecodeImage", {
                    staticClass: "hot-recommend",
                    attrs: { path: _vm.downloadRecommendTagImg }
                  })
                : _vm._e(),
              _vm.buttonImg[btn.type]
                ? _c("DecodeImage", {
                    staticClass: "button-img",
                    attrs: { path: _vm.buttonImg[btn.type] }
                  })
                : _vm._e(),
              _c("span", { style: _vm.buttonBeforeStyle }),
              _vm._v(" " + _vm._s(btn.title) + " "),
              _c("span", { style: _vm.buttonAfterStyle })
            ],
            1
          )
        }),
        0
      ),
      _c("KindTitps", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTitp,
            expression: "showTitp"
          }
        ],
        attrs: {
          appLogoIcon: _vm.appLogoIcon,
          "logo-icon": _vm.logoIcon,
          "ios-tips": _vm.iosTips
        },
        on: {
          hide: function($event) {
            _vm.showTitp = false
          },
          btnClick: _vm.onDefaultBtnClick
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }